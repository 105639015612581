import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

const Shop = ({ data }) => {
  return (
    <React.Fragment>
      <div className="shop"> <div><h2>(test mode o meglio solo se mi vieni a bussare a casa)</h2></div>
        <section className="hero ">
          <h2>
            I nostri&nbsp;<span>prodotti </span>
          </h2>
         
          <Img
            className="img img-section"
            fluid={data.allFile?.nodes[17]?.childImageSharp.fluid}
            alt="basket of vegetables"
          />
        </section>
        <section className="showcase">
          <Img
            className="img__showcase"
            fluid={data.allFile?.nodes[19]?.childImageSharp?.fluid}
            alt="vegetables"
          />
          <div className="showcase__description reverse">
            <Link replace to="/vegetables">
              <h3>Coltiviamo nel rispetto della natura</h3>
              <p>
                Coltiviamo nel rispetto della natura in quanto riconosciamo
                vivamente ad essa la vita su questo pianeta, e per questo
                preservare e prolungare la fertilità di questa terra è alla
                base dei nostri principi
              </p>
              <h4 className="link-shop">Prodotti di origine vegetale</h4>
            </Link>
          </div>
        </section>
        <div className="svg-container">
          <div className="item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45.5"
              height="49.388"
              viewBox="0 0 45.5 49.388"
            >
              <path
                id="Path_17371"
                data-name="Path 17371"
                d="M63.779,11.523a1.281,1.281,0,0,0-.124-.547,1.2,1.2,0,0,0-.836-.66L41.806.124a1.247,1.247,0,0,0-1.083,0L19.5,10.409a1.242,1.242,0,0,0-.7,1.073V37.345a1.229,1.229,0,0,0,.7,1.114L40.722,48.745c.01,0,.01,0,.021.01a.367.367,0,0,1,.093.041c.01,0,.021.01.041.01l.093.031c.01,0,.021.01.031.01.031.01.072.01.1.021h.031c.041,0,.093.01.134.01a.588.588,0,0,0,.134-.01h.031a.366.366,0,0,0,.1-.021c.01,0,.021-.01.031-.01l.093-.031c.01,0,.021-.01.041-.01a.367.367,0,0,0,.093-.041c.01,0,.01,0,.021-.01L63.1,38.428a1.248,1.248,0,0,0,.7-1.114V11.554C63.779,11.544,63.779,11.534,63.779,11.523ZM41.259,2.61l18.4,8.924-6.778,3.291L34.481,5.9Zm0,17.847-18.4-8.924,8.79-4.261,18.4,8.924ZM21.276,13.525l18.745,9.089V45.65L21.276,36.561ZM42.5,45.65V22.613l8.8-4.271v6.025a1.238,1.238,0,1,0,2.476,0V17.135L61.3,13.483V36.52Z"
                transform="translate(-18.55 0.259)"
                fill="#9fa2a7"
                stroke="#f7f7f7"
                stroke-width="0.5"
              />
            </svg>
            <h4>Delivery gratis</h4>
            <p>Per ordini superiori ai $50</p>
          </div>
          <div className="item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50.2"
              height="46.033"
              viewBox="0 0 50.2 46.033"
            >
              <g id="refund" transform="translate(-1.9 -4.9)">
                <path
                  id="Path_17363"
                  data-name="Path 17363"
                  d="M43.667,23.094V12.845l5-5V25h1.667V5.833h-.007A.811.811,0,0,0,49.5,5H9.5a.83.83,0,0,0-.589.244L2.245,11.91A.832.832,0,0,0,2,12.5V44.167A.833.833,0,0,0,2.833,45h40a.833.833,0,0,0,.833-.833V34.2A9.57,9.57,0,0,1,35.75,49.167a.833.833,0,1,0,0,1.667h1.667a14.571,14.571,0,0,0,6.25-27.739ZM42.488,11.667H27.345l5-5H47.488Zm-17.744.244a.83.83,0,0,0-.244.589v5.833H21.167V12.845l6.178-6.178h2.643ZM9.845,6.667H24.988l-5,5H4.845ZM3.667,43.333v-30H19.5v5.833a.833.833,0,0,0,.833.833h5a.833.833,0,0,0,.833-.833V13.333H42v9.082a14.488,14.488,0,0,0-4.583-.749h-1.25V17.5a.833.833,0,0,0-1.318-.678L23.182,25.155a.833.833,0,0,0,0,1.356l11.667,8.333a.833.833,0,0,0,1.318-.677V30.009A9.542,9.542,0,0,1,42,32.325V43.333Zm40.04,4.2a11.245,11.245,0,0,0-7.957-19.2h-.417a.833.833,0,0,0-.833.833v3.381l-9.4-6.714,9.4-6.714V22.5a.833.833,0,0,0,.833.833h2.083a12.915,12.915,0,0,1,6.29,24.2Z"
                  fill="#9fa2a7"
                  stroke="#9fa2a7"
                  stroke-width="0.2"
                />
              </g>
            </svg>
            <h4>Refundable</h4>
            <p>If your item have no damage we agree to refund it.</p>
          </div>
          <div className="item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40.5"
              height="51.882"
              viewBox="0 0 40.5 51.882"
            >
              <g id="security" transform="translate(0.195 0.26)">
                <path
                  id="Path_17366"
                  data-name="Path 17366"
                  d="M39.546,10.4,20.24.113a.959.959,0,0,0-.914.005L.554,10.406a.961.961,0,0,0-.5.843V24.687A27.824,27.824,0,0,0,16.732,50.152l2.606,1.135a.96.96,0,0,0,.765,0l2.936-1.268a27.77,27.77,0,0,0,17.017-25.6V11.249a.962.962,0,0,0-.509-.849ZM38.133,24.42A25.851,25.851,0,0,1,22.286,48.252l-.006,0-2.557,1.1L17.5,48.39a25.9,25.9,0,0,1-15.524-23.7V11.818L19.794,2.053l18.339,9.772Zm0,0"
                  transform="translate(0 0)"
                  fill="#9fa2a7"
                  stroke="#9fa2a7"
                  stroke-width="0.5"
                />
                <path
                  id="Path_17367"
                  data-name="Path 17367"
                  d="M81.534,150.051a.961.961,0,0,0-1.462,1.248l5.077,5.946a.961.961,0,0,0,1.337.122l11.825-9.62a.961.961,0,1,0-1.213-1.491L86,155.282Zm0,0"
                  transform="translate(-68.831 -125.987)"
                  fill="#9fa2a7"
                  stroke="#9fa2a7"
                  stroke-width="0.5"
                />
              </g>
            </svg>
            <h4>Pagamenti Sicuri</h4>
            <p>100% pagamenti sicuri</p>
          </div>
          <div className="item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45.59"
              height="45.545"
              viewBox="0 0 45.59 45.545"
            >
              <g
                id="help_1_"
                data-name="help (1)"
                transform="translate(0.295 0.25)"
              >
                <path
                  id="Path_17368"
                  data-name="Path 17368"
                  d="M45,30.454A14.579,14.579,0,0,0,34.348,16.437,17.182,17.182,0,1,0,2.383,25.916L.063,34.3l8.386-2.319a17.119,17.119,0,0,0,7.988,2.365,14.55,14.55,0,0,0,21.418,8.63l7.081,1.959-1.959-7.081A14.494,14.494,0,0,0,45,30.454ZM8.865,29.132,3.845,30.52,5.234,25.5l-.317-.5A14.541,14.541,0,1,1,9.36,29.448Zm32.29,12.024L37.43,40.124l-.5.324a11.914,11.914,0,0,1-17.769-6.2A17.215,17.215,0,0,0,34.251,19.163a11.914,11.914,0,0,1,6.2,17.769l-.324.5Zm0,0"
                  fill="#9fa2a7"
                  stroke="#f7f7f7"
                  stroke-width="0.5"
                />
                <path
                  id="Path_17369"
                  data-name="Path 17369"
                  d="M180.5,271h2.637v2.637H180.5Zm0,0"
                  transform="translate(-164.636 -247.182)"
                  fill="#9fa2a7"
                  stroke="#f7f7f7"
                  stroke-width="0.5"
                />
                <path
                  id="Path_17370"
                  data-name="Path 17370"
                  d="M143.41,95.274a2.61,2.61,0,0,1-.858,1.946l-3.1,2.834v3.217h2.637v-2.056l2.24-2.05a5.273,5.273,0,1,0-8.832-3.892h2.637a2.637,2.637,0,0,1,5.273,0Zm0,0"
                  transform="translate(-123.591 -82.09)"
                  fill="#9fa2a7"
                  stroke="#f7f7f7"
                  stroke-width="0.5"
                />
              </g>
            </svg>
            <h4>24/7 Servizio Clienti</h4>
            <p>Abbiamo supporto dedicato</p>
          </div>
        </div>
        <section className="showcase ">
          <Img
            className="img__showcase carne"
            fluid={data?.allFile?.nodes[13]?.childImageSharp.fluid}
            alt="carne di agnello"
          />
          <div className="showcase__description ">
            <Link to="#">
              <h3>I nostri animali vengono allevati all' aperto</h3>
              <p>
                Gli animali hanno sempre fatto parte della sopravvivenza su
                queste montagne, oggigiorno hanno maggiore libertà di pascolare
                all'aperto. Il formaggio e la carne sono di qualità rilevanti
              </p>
              <h4 className="link-shop">Prodotti di origine animale</h4>
            </Link>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Shop;

export const query = graphql`
  query shopQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "images" } }
      sort: { fields: base }
    ) {
      nodes {
        name
        absolutePath
        childImageSharp {
          fluid {
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
    }
  }
`;
